<template>
  <div
    v-if="isLoggedIn"
    id="reseller"
  >
    <router-view />
  </div>
  <!-- ===== LOGIN ===== -->
  <LoginEmail v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import LoginEmail from './Login/LoginEmail.vue';

export default {
  name: 'Reseller',
  components: {
    LoginEmail,
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
  },
};
</script>

<style lang="scss">
@import '../styles/variables/_colors.scss';
@import '../styles/variables/_sizes.scss';
@import '../styles/theme/_default.scss';

#internal {
  height: inherit;
  padding: 30px 0 30px 0;
}
</style>
