<template>
  <div v-if="initializing">
    <b-spinner
      variant="primary"
      label="Loading"
      class="initializing"
    />
  </div>
  <div
    v-else
    id="login-email"
  >
    <h2>Verify Your Email</h2>
    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <FormSection
          v-if="!verifying"
          description="Enter your email address and we'll email you a code to log in."
        >
          <FormGroup
            for="email"
            label="Email Address"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true, email: true }"
              :disabled="disabled"
              :state="validateState('Email')"
              name="Email"
              bind-id="email"
              autocomplete="email"
              get="email"
              set="UPDATE_EMAIL"
              invalid-feedback="Please enter a valid Email."
              invalid-feedback-id="email-invalid-feedback"
              @keyup.enter.native="sendCode"
            />
          </FormGroup>
        </FormSection>
        <FormSection
          v-if="verifying"
          :description="`Enter the 4-digit code sent to you at ${email}`"
        >
          <FormGroup
            id="verification-code"
            :state="state"
            :class="[{'invalid': state}]"
            :invalid-feedback="invalidFeedback"
            for="auth"
            role="alert"
          >
            <b-input
              id="verification-code-field"
              v-model="auth"
              type="tel"
              maxlength="4"
            />
          </FormGroup>
        </FormSection>
        <p
          v-if="!verifying"
          class="blurp mb-4"
        >
          Please contact support@aira.io if you do not have an account.
        </p>
        <b-button
          v-if="!verifying"
          id="login-btn"
          :disabled="disabled"
          aria-label="Continue"
          variant="primary"
          size="lg"
          class="w-100"
          @click="sendCode()"
        >
          Continue
        </b-button>
        <b-button
          v-if="verifying"
          :disabled="disabled"
          class="link-button"
          size="sm"
          variant="link"
          @click="sendCode"
        >
          {{ resendCodeText }}
        </b-button>
        <b-button
          v-if="verifying"
          class="link-button"
          size="sm"
          variant="link"
          @click="back"
        >
          Back
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ValidationMixin from '../../mixin/ValidationMixin';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';

export default {
  name: 'LoginEmail',
  components: {
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      initializing: true,
      disabled: false,
      verifying: false,
      auth: '',
      timer: 0,
      state: null,
      invalidFeedback: '',
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'email',
      'role',
    ]),
    resendCodeText() {
      if (this.timer > 0) {
        return `Resend Code in ${this.timer}`;
      } else {
        return 'Resend Code';
      }
    },
  },
  watch: {
    auth(val) {
      this.state = null;
      this.invalidFeedback = '';
      if (val.length === 4) {
        this.verify();
      }
    },
  },
  async mounted() {
    try {
      if (this.$session.exists()) {
        await this.$store.dispatch('setSsoToken', this.$session.get('sso-token'));
        const login = await this.$store.dispatch('validateUserToken');
        if (!login) {
          // login failed, destroy session and cached data (session, sso-token)
          this.$session.destroy();
          this.initializing = false;
        } else {
          this.initializing = false;
        }
      } else {
        this.initializing = false;
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    progress() { return { full: true }; },
    back() {
      this.verifying = false;
      this.disabled = false;
    },
    async sendCode() {
      this.disabled = true;
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');

      try {
        const validation = await this.$validator.validateAll();

        if (!validation) {
          const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
          this.$store.commit('PUSH_ERRORS', fieldErrors);
          this.disabled = false;
          return;
        }

        this.$store.dispatch('postVerifyEmail').then((response) => {
          if (response) {
            this.minuteCountdown();
            this.verifying = true;
          }
        }).catch((error) => {
          console.error(error);
          this.$store.commit('PUSH_ERRORS', [`Error: ${error.errorMessage}`]);
          this.disabled = false;
        });
      } catch (error) {
        console.error(error);
        this.disabled = false;
      }
    },
    async verify() {
      if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
      try {
        const response = await this.$store.dispatch('postVerifyEmailConfirm', this.auth);
        if (response.verificationCode) {
          // login
          const login = await this.$store.dispatch('login', {
            login: this.email,
            password: response.verificationCode,
            authProvider: 'EMAIL_VERIFICATION',
            loginfrom: 'SSP',
          });
          if (login) {
            this.$session.start();
            this.$session.set('jwt', login);
            this.$session.set('sso-token', login);
            await this.$store.dispatch('getUserProfile');
            await this.$store.dispatch('getUserBilling');
          } else {
            this.state = false;
            this.invalid = 'error';
            this.auth = '';
          }
        } else {
          this.state = false;
          this.invalidFeedback = 'error';
          this.auth = '';
        }
      } catch (error) {
        console.error(error);
        this.$store.commit('PUSH_ERRORS', [`Error: ${error.errorMessage}`]);
      }
    },
    minuteCountdown() {
      this.disabled = true;
      this.timer = 60;
      const minuteTimer = setInterval(() => {
        this.timer -= 1;
        if (this.timer <= 0) {
          this.disabled = false;
          clearInterval(minuteTimer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
$char-w: 1ch;
$gap: .4*$char-w;
$n-char: 4;
$in-w: $n-char*($char-w + $gap);

.initializing {
  margin: 100px;
}

#login-email {
  padding: 40px;
  button {
    display: block;
    margin: 0 auto;
  }
  .form {
    padding: 40px 20px 20px 20px;
  }
  .blurp {
    text-align: left;
    font-size: small;
    opacity: 0.6;
  }
  #login-btn {
    margin: auto;
    width: auto;
    height: auto;
  }
  .link-button {
    height: 25px;
  }
  .invalid-feedback {
    position: absolute;
  }
  #verification-code {
    width: 170px;
    height: 55px;
    margin: 2em auto;
    border: 1px solid;
    padding: 0px 25px 5px 30px;
    border-radius: 8px;
    &:focus-within {
      border: 1px solid #21BECE;
    }
    .invalid-feedback {
      display: inline-flex !important;
      position: relative;
      margin-left: -50px;
      margin-top: 10px;
      width: 250px;
    }
    #verification-code-field {
      border-radius: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      font-weight: 600;
      font-size: 36px !important;
      line-height: 43px;
      display: block;
      width: $in-w;
      background: repeating-linear-gradient(90deg,
        dimgrey 0, dimgrey $char-w,
        transparent 0, transparent $char-w + $gap)
        0 100%/ #{$in-w - $gap} 3px no-repeat;
      letter-spacing: $gap;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
</style>
